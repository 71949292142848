import React from 'react';

import Alert from 'snap-ui/Alert';
import { FormDialog } from 'snap-ui/Dialog';
import Divider from 'snap-ui/Divider';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import { ArtifactType } from 'types/common';

import { JsonView } from '.';
import { Metadata } from './Metadata.type';
import { transform } from './Metadata.util';

const Current = styled(props => <Typography {...props}>Current</Typography>)`
  color: ${p => p.theme.palette.success.main};
` as typeof Typography;

const Migration = styled('div')`
  margin-top: ${p => p.theme.spacing(8)};
  display: flex;
  justify-content: space-evenly;

  .MetadataMigrate-view {
    width: 45%;
    min-width: min-content;
  }

  .group-layout {
    margin: ${p => p.theme.spacing(3, 0)};
    display: flex;
    flex-direction: column;
    gap: ${p => p.theme.spacing(3)};
  }

  .MetadataMigrate-stale {
    color: ${p => p.theme.palette.warning.main};
  }

  .Control {
    width: 100%;
    max-width: 256px; /* magic number */
  }
`;

type MetadataMigrate = {
  data: Metadata;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: Record<string, unknown>) => void;
  type: ArtifactType;
};

function _MetadataMigrate({ data, isOpen, onClose, onSubmit, type }: MetadataMigrate) {
  const initialValues = React.useMemo(() => {
    if (!data) return {};
    return Object.entries(data.activeMeta.properties).reduce((prev, [key, value]) => {
      return {
        ...prev,
        [key]: data.value[key] || value.default
      };
    }, {});
  }, [data]);

  const handleSubmit = (values: Record<string, unknown>) => {
    const payload = Object.entries(values).reduce(
      (prev, [key, value]) => ({
        ...prev,
        // boolean and number should should be set as undefined when value is 'any' and ''. Its ok for empty array to go as []
        [key]:
          value === 'any'
            ? undefined
            : data.activeMeta.properties[key].type === 'number' && value === ''
            ? undefined
            : transform(value)
      }),
      {}
    );
    onSubmit(payload);
  };

  return (
    <FormDialog
      title='Metadata Migration'
      DialogProps={{ open: isOpen, onClose, fullWidth: true }}
      SubmitProps={{ children: 'Submit' }}
      FormikConfig={{ onSubmit: handleSubmit, initialValues, enableReinitialize: true }}
    >
      {({ setValues }) => (
        <>
          <Alert severity='warning'>
            Any data for fields not present with the <Current component='span' /> schema (right side) will be lost
          </Alert>
          <Migration>
            {data?.meta && (
              <>
                <div className='MetadataMigrate-view'>
                  <Typography variant='h4' component='div' className='MetadataMigrate-stale'>
                    Stale
                  </Typography>
                  <JsonView
                    asPreview
                    schema={data.meta}
                    uischema={data.meta.uischema}
                    readonly
                    data={data.value}
                    type={type}
                  />
                </div>
                <Divider orientation='vertical' flexItem light />
                <div className='MetadataMigrate-view'>
                  <Current variant='h4' component='div' />
                  <JsonView
                    asPreview
                    schema={data.activeMeta}
                    uischema={data.activeMeta.uischema}
                    data={initialValues}
                    onChange={v => setValues(v.data)}
                    type={type}
                  />
                </div>
              </>
            )}
          </Migration>
        </>
      )}
    </FormDialog>
  );
}

export function MetadataMigrate(props: MetadataMigrate) {
  if (!props.isOpen) return null;
  return <_MetadataMigrate {...props} />;
}
