import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { CancelTokenSourceType } from 'apis/snapattack';

import usePaginate from 'hooks/usePaginate';

import { Collection, CollectionDiscriminator } from 'module/Collection/Collection.type';
import { RecommendationType } from 'module/Curation/Curation.type';
import { FEED_SKELETON } from 'module/Feed/Feed.const';
import { getSearchPage } from 'module/Feed/Feed.service';
import { Feed, SortOrder } from 'module/Feed/Feed.type';
import { useFilterRegistry } from 'module/GlobalFilter';
import { getIndicators } from 'module/IOC/IOC.api';
import { IOCArtifact, isIOCCompatibleCollection } from 'module/IOC/IOC.type';
import { mapIocType } from 'module/IOC/IOCParser/IOCParser.util';
import { getLandingStatsCount } from 'module/Landing/Landing.service';
import { LandingStats } from 'module/Landing/Landing.type';
import { useMayI } from 'module/May';

import { useSortOrder, useUserConfig } from 'provider/UserConfig';

import { Helper, Meta, useAsync } from 'storage';

import { FunctionalPermission } from 'types/auth';
import { Artifact, ArtifactType } from 'types/common';
import { Ops, Query } from 'types/filter';

import { NOOP } from 'utilities/FunctionUtils';
import { getMomentFromDelta } from 'utilities/TimeUtils';

type PageFetcher = (page: number, query: Query, cancelToken?: CancelTokenSourceType) => void;
type ArtifactAsyncBag = Omit<Meta<Feed> & Helper<Feed>, 'run'>;

export type CurationFeedInterface = {
  [ArtifactType.Intel]: ArtifactAsyncBag;
  intelPage: PageFetcher;
  [ArtifactType.Session]: ArtifactAsyncBag;
  threatPage: PageFetcher;
  [ArtifactType.Analytic]: ArtifactAsyncBag;
  detectionPage: PageFetcher;
  [ArtifactType.AttackScript]: ArtifactAsyncBag;
  validationPage: PageFetcher;
  getQueryPayload(topic: ArtifactType, query?: Query): Query;
} & IndicatorFeedInterface;

export default function useCollectionArtifacts(collection: Collection, lengthOfRun: number): CurationFeedInterface {
  const { sortOrder } = useUserConfig();
  const hasPermission = useMayI(FunctionalPermission.RecommendationFeedFilter);

  const { run: fetchIntel, ...intelligence } = useAsync<Feed>(FEED_SKELETON);
  const { run: fetchThreat, ...threat } = useAsync<Feed>(FEED_SKELETON);
  const { run: fetchDetection, ...detection } = useAsync<Feed>(FEED_SKELETON);
  const { run: fetchAttackScript, ...attackScript } = useAsync<Feed>(FEED_SKELETON);
  const { indicator, indicatorPage } = useLandingIndicators(collection.name, collection.type, lengthOfRun);
  const { values: filter } = useFilterRegistry(ArtifactType.Analytic);
  const useCollectionBaseQuery =
    !hasPermission || !Object.values(RecommendationType).includes(filter?.recommended as RecommendationType);

  const getQueryPayload = React.useCallback(
    (topic: ArtifactType, query?: Query) => {
      switch (topic) {
        case ArtifactType.Intel:
          return collection.threat_intelligence_filter ? payload(query, collection.threat_intelligence_filter) : null;
        case ArtifactType.Session:
          return collection.session_filter ? payload(query, collection.session_filter) : null;
        case ArtifactType.Analytic:
          return useCollectionBaseQuery
            ? collection.analytic_filter
              ? payload(query, collection.analytic_filter)
              : null
            : payload(query);
        case ArtifactType.AttackScript:
          return useCollectionBaseQuery
            ? collection.bas_script_filter
              ? payload(query, collection.bas_script_filter)
              : null
            : payload(query);
      }
    },
    [
      collection.analytic_filter,
      collection.bas_script_filter,
      collection.session_filter,
      collection.threat_intelligence_filter,
      useCollectionBaseQuery
    ]
  );

  const intelPage = React.useCallback(
    (page: number, _query: Query, cancelToken?: CancelTokenSourceType) => {
      const query = getQueryPayload(ArtifactType.Intel, _query);
      if (isEmpty(query)) return;
      fetchIntel(getSearchPage(ArtifactType.Intel, page, lengthOfRun, query, sortOrder, cancelToken));
    },
    [getQueryPayload, fetchIntel, lengthOfRun, sortOrder]
  );

  const threatPage = React.useCallback(
    (page: number, _query: Query, cancelToken?: CancelTokenSourceType) => {
      const query = getQueryPayload(ArtifactType.Session, _query);
      if (isEmpty(query)) return;
      fetchThreat(getSearchPage(ArtifactType.Session, page, lengthOfRun, query, sortOrder, cancelToken));
    },
    [getQueryPayload, fetchThreat, lengthOfRun, sortOrder]
  );

  const detectionPage = React.useCallback(
    (page: number, _query: Query, cancelToken?: CancelTokenSourceType) => {
      const query = getQueryPayload(ArtifactType.Analytic, _query);
      if (isEmpty(query)) return;
      fetchDetection(getSearchPage(ArtifactType.Analytic, page, lengthOfRun, query, sortOrder, cancelToken));
    },
    [getQueryPayload, fetchDetection, lengthOfRun, sortOrder]
  );

  const validationPage = React.useCallback(
    (page: number, _query: Query, cancelToken?: CancelTokenSourceType) => {
      const query = getQueryPayload(ArtifactType.AttackScript, _query);
      if (isEmpty(query)) return;
      fetchAttackScript(getSearchPage(ArtifactType.AttackScript, page, lengthOfRun, query, sortOrder, cancelToken));
    },
    [getQueryPayload, fetchAttackScript, lengthOfRun, sortOrder]
  );

  return {
    intelligence,
    intelPage,
    threat,
    threatPage,
    detection,
    detectionPage,
    attack_script: attackScript,
    validationPage,
    indicator,
    indicatorPage,
    getQueryPayload
  };
}

type IndicatorFeedInterface = {
  [ArtifactType.Indicator]: ArtifactAsyncBag & {
    filteredItems: IOCArtifact[];
  };
  indicatorPage: PageFetcher;
};

function useLandingIndicators(name: string, type: CollectionDiscriminator, pageSize: number): IndicatorFeedInterface {
  const { run, data, ...indicator } = useAsync<IOCArtifact[]>([]);
  const { run: countRun, data: count } = useAsync<LandingStats>();
  const isIndicatorUser = useMayI(FunctionalPermission.LandingIndicators);

  const { sortOrder } = useSortOrder();
  const { values } = useFilterRegistry(ArtifactType.Indicator);

  const filteredData = React.useMemo(() => {
    let indicators = isEmpty(data) ? [] : data.slice();

    if (Number(values?.indicatorScore) > 70) {
      indicators = indicators.filter(indicator => indicator.score >= Number(values?.indicatorScore));
    }

    if (!isEmpty(values?.indicatorType)) {
      indicators = indicators.filter(indicator => values?.indicatorType.includes(mapIocType(indicator.type)));
    }

    if (values?.firstSeen) {
      indicators = indicators.filter(indicator => {
        const date = new Date(Date.parse(indicator.first_seen));
        const firstSeenThreshold = getMomentFromDelta(values.firstSeen as string);
        return date.getTime() >= firstSeenThreshold.valueOf();
      });
    }

    if (values?.lastSeen) {
      indicators = indicators.filter(indicator => {
        const date = new Date(Date.parse(indicator.last_seen));
        const lastSeenThreshold = getMomentFromDelta(values?.lastSeen as string);
        return date.getTime() >= lastSeenThreshold.valueOf();
      });
    }

    if (values?.query) {
      indicators = indicators.filter(indicator => indicator.name.includes(values.query as string));
    }

    switch (sortOrder) {
      case SortOrder.creation:
        indicators.sort((a, b) => {
          const aFirstSeen = new Date(a.first_seen).getTime();
          const bFirstSeen = new Date(b.first_seen).getTime();
          return aFirstSeen > bFirstSeen ? -1 : 1;
        });
        break;
      case SortOrder.modified:
        indicators.sort((a, b) => {
          const aLastSeen = new Date(a.last_seen).getTime();
          const bLastSeen = new Date(b.last_seen).getTime();
          return aLastSeen > bLastSeen ? -1 : 1;
        });
        break;
      case SortOrder.relevance:
        indicators.sort((a, b) => (a.score > b.score ? -1 : 1));
        break;
    }

    return indicators;
  }, [data, sortOrder, values]);

  const { page, pageData, pageTotal, handleChangePage: setPage } = usePaginate<IOCArtifact>(filteredData, pageSize);

  const refresh = React.useCallback(() => {
    if (name && type && isIOCCompatibleCollection(type)) {
      if (isIndicatorUser) {
        run(getIndicators(name, type));
      } else {
        countRun(getLandingStatsCount(type as Artifact['type'], name));
      }
    }
  }, [countRun, isIndicatorUser, name, run, type]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  const indicatorPage = React.useCallback((page: number) => setPage(page - 1), [setPage]);

  return {
    indicator: {
      data: {
        items: pageData as Artifact[],
        page,
        size: pageSize,
        total: isIndicatorUser ? filteredData.length : count?.indicators ?? 0,
        pageTotal
      },
      filteredItems: filteredData,
      error: indicator.error,
      status: indicator.status,
      setData: NOOP,
      setWith: NOOP,
      setError: indicator.setError,
      reset: indicator.reset
    },
    indicatorPage
  };
}

function payload(query: Query, guids?: Query): Query {
  if (isEmpty(guids)) return query;

  return query
    ? {
        op: Ops.and,
        items: [guids, query]
      }
    : guids;
}
