import React from 'react';

import isEmpty from 'lodash/isEmpty';

import {
  DataGrid,
  GRID_AGGREGATION_FUNCTIONS,
  GridColDef,
  GridFilterModel,
  GridLogicOperator,
  GridValueGetterParams,
  gridStringOrNumberComparator,
  useGridApiRef,
  useKeepGroupedColumnsHidden
} from 'snap-ui/DataGrid';
import { getRowForSort } from 'snap-ui/DataGrid/DatGrid.helper';
import Placeholder from 'snap-ui/Placeholder';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import { DEFAULT_JOB_TYPE, JobGroup, JobOverview, JobStatus, JobType } from 'module/Job';
import { getGridColumnsTask } from 'module/Job/Job.util';
import { JobGridTreeDataGroupingCell, TaskTableToolbar } from 'module/Job/Job.widgets';
import {
  aggregateCreated,
  aggregateCreatedBy,
  aggregateIntegration,
  aggregateOrg,
  aggregateProgress,
  aggregateStatus,
  aggregateTimeRemaining,
  aggregateType,
  created_by,
  creation,
  detections,
  hits,
  integrationNames,
  jobGuid,
  jobName,
  jobsByGroup,
  modified,
  organization,
  progress,
  sumUp,
  taskStatus,
  timeRemaining,
  type
} from 'module/Job/Task/Column';

import { useAuth, useIntegrationCatalog, useManagedOrganizations, useUserConfig } from 'provider';

import { Ident } from 'types/common';

import { getIntegrationNameFromJob } from './BulkConfidence.helper';

const Container = styled('div')`
  height: calc(75vh - ${p => p.theme.spacing(9)} * 2 - ${p => p.theme.spacing(7)});
  .no-break {
    width: 100%;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .disable {
    font-style: italic;
    color: ${p => p.theme.palette.grey[500]};
  }
`;

export type BulkConfidenceTaskProps = {
  getJob: (JobGroup) => void;
  isPending: boolean;
  jobGroupTask: JobGroup[];
  jobs: JobOverview[];
};

const COLUMN_MODEL_CONFIDENCE_LIST_TABLE = 'confidenceJobOverview';

export default function BulkConfidenceTask({ getJob, isPending, jobGroupTask, jobs }: BulkConfidenceTaskProps) {
  const { integrations } = useIntegrationCatalog();
  const { columnModel, setColumnModel } = useUserConfig();
  const { user, defaultOrgId } = useAuth();
  const { organizations } = useManagedOrganizations();
  const orgId = user.superuser ? null : defaultOrgId;
  const apiRef = useGridApiRef();

  const [jobTypeFilter, setJobTypeFilter] = React.useState<JobType | 'All'>(JobType.Rank);
  const [orgFilter, setOrgFilter] = React.useState<Ident>(orgId);
  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    items: [],
    quickFilterValues: []
  });

  const filteredJobs = React.useMemo(() => {
    const filteredJobs = jobs;

    return filteredJobs
      .filter(job => {
        if (jobTypeFilter !== DEFAULT_JOB_TYPE) return job.type === jobTypeFilter;
        else return [JobType.Rank, JobType.Hunt].some(type => type === job.type);
      })
      .filter(job => (orgFilter === null ? job : job.organization_id === orgFilter));
  }, [jobs, jobTypeFilter, orgFilter]);

  const bulkConfidenceMap = jobGroupTask
    .map(group => {
      const jobTask = filteredJobs?.find(eachJobData => group.jobs[0]?.guid === eachJobData.analytic_job_guid);
      return {
        ...group,
        ...jobTask,
        name: group?.name || jobTask?.name,
        job_status:
          group.status === JobStatus.CompletedWithErrors || group.status === JobStatus.Success ? 'done' : group.status,
        creation: jobTask?.creation,
        integration_name: getIntegrationNameFromJob(group.jobs, filteredJobs, integrations.all).join(', '),
        detections_completed: group.jobs
          .map(job =>
            filteredJobs
              .map(eachJobData => (job.guid === eachJobData.analytic_job_guid ? eachJobData?.detections_completed : 0))
              .reduce((sum, count) => sum + count, 0)
          )
          .reduce((sum, count) => sum + count, 0),
        type: jobTask?.type
      };
    })
    ?.filter(group => [JobType.Rank, JobType.Hunt].includes(group.type as JobType));
  const handleSetColumn = (value: Record<string, boolean>) =>
    setColumnModel({ ...columnModel, [COLUMN_MODEL_CONFIDENCE_LIST_TABLE]: value });

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ['jobsByGroup']
      },
      filter: {
        filterModel: {
          items: []
        }
      },
      sorting: {
        sortModel: [{ field: 'creation', sort: 'desc' }]
      },
      aggregation: {
        model: {
          created_by: 'aggregateCreatedBy',
          type: 'aggregateType',
          integration_name: 'aggregateIntegration',
          status: 'aggregateStatus',
          progress_percent: 'aggregateProgress',
          detection: 'sumUp',
          hits: 'sumUp',
          estimated_remaining_sec: 'aggregateTimeRemaining',
          creation: 'aggregateCreated',
          modified: 'aggregateCreated',
          organization_id: 'aggregateOrg'
        }
      }
    }
  });

  function onSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFilterModel(model => ({
      ...model,
      items: [
        {
          id: 1,
          field: 'name',
          operator: 'contains',
          value: event.target.value
        },
        {
          id: 2,
          field: 'integration_name',
          operator: 'contains',
          value: event.target.value
        }
      ],
      logicOperator: GridLogicOperator.Or
    }));
  }

  function onTypeChange(type: JobType | 'All') {
    setJobTypeFilter(type);
  }

  function onOrgChange(org: number) {
    setOrgFilter(org);
  }

  return (
    <Container>
      {isPending ? (
        <>
          <Placeholder variant='text' width={'100%'} height={100} />
          <Placeholder variant='text' width={'100%'} height={70} />
          <Placeholder variant='text' width={'100%'} height={70} />
          <Placeholder variant='text' width={'100%'} height={70} />
          <Placeholder variant='text' width={'100%'} height={70} />
          <Placeholder variant='text' width={'100%'} height={70} />
        </>
      ) : (
        <DataGrid
          apiRef={apiRef}
          columns={[
            jobsByGroup,
            jobGuid(user.superuser),
            type,
            created_by,
            integrationNames,
            taskStatus(apiRef),
            progress,
            detections,
            hits,
            timeRemaining,
            creation,
            modified,
            organization(organizations),
            jobName
          ]}
          rows={bulkConfidenceMap}
          groupingColDef={{
            headerName: 'Name',
            flex: 1,
            minWidth: 300,
            sortingOrder: ['asc', 'desc'],
            sortComparator: (v1, v2, param1, param2) => gridStringOrNumberComparator(v1, v2, param1, param2),
            valueGetter(params: GridValueGetterParams) {
              const row = getRowForSort<JobOverview>(apiRef.current, params);
              return row?.name;
            },
            renderCell: params =>
              params.row.job_status !== 'done' ? (
                params.row.name
              ) : (
                <JobGridTreeDataGroupingCell
                  {...params}
                  idPath='analytic_job_guid'
                  linkPath={Path.ConfidenceTailoring}
                />
              ),
            cellClassName: p => (p.row.job_status !== 'done' ? 'disable' : '')
          }}
          initialState={initialState}
          getAggregationPosition={groupNode => (groupNode == null ? null : 'inline')}
          getRowId={row => row.guid}
          disableMultipleRowSelection
          getRowClassName={p => (p.row.job_status !== 'done' ? `disableRow` : null)}
          onRowClick={params => (params.row.job_status === 'done' ? getJob(params.row) : null)}
          onColumnVisibilityModelChange={handleSetColumn}
          filterModel={filterModel}
          columnVisibilityModel={{
            modified: false,
            estimated_remaining_sec: false,
            progress_percent: false,
            analytic_job_guid: user.superuser ? true : false,
            organization_id: user.superuser ? true : false,
            ...(isEmpty(columnModel[COLUMN_MODEL_CONFIDENCE_LIST_TABLE])
              ? undefined
              : columnModel[COLUMN_MODEL_CONFIDENCE_LIST_TABLE]),
            jobsByGroup: false,
            name: false
          }}
          onFilterModelChange={newModel => setFilterModel(newModel)}
          aggregationFunctions={{
            ...GRID_AGGREGATION_FUNCTIONS,
            aggregateType,
            aggregateIntegration,
            aggregateCreatedBy,
            aggregateStatus,
            aggregateProgress,
            aggregateTimeRemaining,
            aggregateCreated,
            sumUp,
            aggregateOrg
          }}
          slots={{
            toolbar: TaskTableToolbar
          }}
          slotProps={{
            toolbar: {
              orgFilter,
              jobTypeFilter,
              onSearchChange,
              onTypeChange,
              onOrgChange,
              includeExport: false,
              jobTypes: [JobType.Rank, JobType.Hunt]
            },
            columnsPanel: {
              getTogglableColumns: (columns: GridColDef[]) => getGridColumnsTask(columns, user.superuser)
            }
          }}
        />
      )}
    </Container>
  );
}
