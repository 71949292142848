import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { create as createAnalytic, save as saveAnalytic } from 'apis/resources/analytic';

import { RawTranslationContext, useRawTranslations } from 'aso/useRawTranslation';
import useWorkflow, { WorkflowResponse } from 'aso/useWorkflow';

import { DateRange, getInitialDatesByPreset } from 'module/DateRangeSelector';
import { useIDEDetectionValue } from 'module/IDE';
import { Integration, IntegrationTarget } from 'module/Integration/Integration.type';

import { useAuth, usePushSnack } from 'provider';
import { useIntegrationCatalog } from 'provider/Integration';

import { isDefaultFPRDetection } from 'services/analyticService';
import { checkContentPermission } from 'services/authService';

import { Status, useAsync } from 'storage';

import { ContentPermission } from 'types/auth';
import { Guid } from 'types/common';

import { FPRCreate, FPROutput } from '../Analytic.type';
import { buildAnalyticPayload } from '../Analytic.util';
import { useAnalyticCatalog } from '../core/AnalyticProvider';
import useEditorStateContext from '../core/EditorStateProvider';
import useFalsePositiveReduction, { TuningBag } from '../useFalsePositiveReduction';
import { FieldType } from './AnalyticTuning.types';

interface TuningInterface {
  applyTuning: () => Promise<Guid>;
  isSaving: boolean;
  canEdit: boolean;
  isLogicChanged: boolean;
  fieldType: FieldType;
  fpr: FPROutput;
  dates: DateRange;
  setDates: React.Dispatch<React.SetStateAction<DateRange>>;
  exclusionsLoaded: boolean;
  setExclusionsLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  selectedLanguage: IntegrationTarget;
  integrationGuid: Guid;
  setIntegrationGuid(guid: Guid): void;
  integrationType: Integration['type'];
  deploymentBag: WorkflowResponse;
  taskBag: ReturnType<typeof useAsync>;
  translationBag: RawTranslationContext;
  tuningBag: TuningBag;
  setShouldTranslate(shouldTranslate: boolean): void;
}

const TuningContext = React.createContext<TuningInterface>(null);
TuningContext.displayName = 'TuningContext';

export default function useTuningContext() {
  const state = React.useContext(TuningContext);
  if (!state) throw new Error('useTuningContext must be used in the TuningProvider');
  return state;
}

export const INITIAL_PRESET = 30;
export function TuningProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const { defaultOrgId, user } = useAuth();
  const pushSnack = usePushSnack();
  const [fieldType] = React.useState<FieldType>(FieldType.Raw);
  const { integrations } = useIntegrationCatalog();
  const { dispatch, state, tagOptions } = useEditorStateContext();
  const { value: customizationValue, updateCondition } = useIDEDetectionValue('customization');

  const tuningBag = useFalsePositiveReduction();

  const [dates, setDates] = React.useState<DateRange>(() => getInitialDatesByPreset(INITIAL_PRESET));
  const [integrationGuid, setIntegrationGuid] = React.useState<Guid>(null);
  const [integrationType, setIntegrationType] = React.useState<string>('');
  const [exclusionsLoaded, setExclusionsLoaded] = React.useState<boolean>(false);
  const [selectedLanguages, setSelectedLanguages] = React.useState<IntegrationTarget[]>([]);
  const [shouldTranslate, setShouldTranslate] = React.useState<boolean>();
  const taskBag = useAsync<void>(null);

  React.useEffect(() => {
    const integration = [...integrations.deployable, ...integrations.huntable].find(i => i.guid === integrationGuid);
    if (integration) {
      setIntegrationType(integration.type);
      const targets = !isEmpty(integration?.deployment_targets)
        ? integration.deployment_targets
        : integration.hunt_targets;
      setSelectedLanguages(targets);
    } else {
      setSelectedLanguages([]);
    }
  }, [integrationGuid, integrations.deployable, integrations.huntable]);

  const [{ analytic, supplemental, supplementalRefresh, permissions, getFpr, fprRefresh, updateAnalytic }] =
    useAnalyticCatalog();
  const canEdit = checkContentPermission(permissions, ContentPermission.Edit);
  const deploymentBag = useWorkflow(analytic.guid);

  const isSaving = [taskBag.status, deploymentBag.status, tuningBag.status].includes(Status.pending);

  const deployments = React.useMemo(
    () => supplemental?.deployments?.filter(d => d.organization_id === defaultOrgId),
    [defaultOrgId, supplemental]
  );

  const fpr = React.useMemo(() => getFpr(integrationGuid), [getFpr, integrationGuid]);

  const fprRaw = state._customization_raw;
  const isLogicChanged = fpr?.raw !== fprRaw;

  const canTranslate = !isEmpty(selectedLanguages) && !!fprRaw;
  const selectedLanguageIds = React.useMemo(() => selectedLanguages?.map(l => l.id), [selectedLanguages]);

  const { translations, status } = useRawTranslations(
    canTranslate && shouldTranslate,
    analytic.raw,
    selectedLanguageIds,
    fprRaw,
    analytic.is_native
  );

  const validTranslation = React.useMemo(() => {
    return translations.find(t => !t.error && !isEmpty(t.fpr)) || translations.find(t => !t.error && !isEmpty(t.raw));
  }, [translations]);

  const translationError = React.useMemo(() => {
    return validTranslation ? null : translations.find(t => t.error)?.error;
  }, [translations, validTranslation]);

  const translationBag: RawTranslationContext = React.useMemo(
    () => ({
      translation: validTranslation?.raw ?? analytic.raw,
      fprTranslation: validTranslation?.fpr?.raw,
      status,
      error: translationError
    }),
    [analytic.raw, status, translationError, validTranslation?.fpr?.raw, validTranslation?.raw]
  );

  const selectedLanguage = React.useMemo(() => {
    if (isEmpty(selectedLanguages)) return null;
    return validTranslation?.fpr ? selectedLanguages.find(s => s.id === validTranslation.id) : selectedLanguages[0];
  }, [selectedLanguages, validTranslation]);

  React.useEffect(() => {
    if (analytic.is_native && validTranslation?.fpr?.raw) {
      dispatch({ type: 'RawUpdateAction', raw: validTranslation?.fpr?.raw, tagOptions });
    }
  }, [analytic.is_native, dispatch, tagOptions, validTranslation?.fpr?.raw]);

  // keep condition in sync
  React.useEffect(() => {
    if (customizationValue && !isDefaultFPRDetection(customizationValue)) {
      const newCondition = `not ( ${customizationValue.sections.map(section => section.name).join(' or ')} ) `;

      if (newCondition !== customizationValue.condition) {
        updateCondition(newCondition);
      }
    }
  }, [customizationValue, updateCondition]);

  const applyTuning = React.useCallback(async () => {
    if (analytic.is_native || state.isNative) {
      const payload = buildAnalyticPayload(state, user.name);

      // originally native with edit permission
      const action = canEdit && analytic.is_native ? saveAnalytic(analytic.guid, payload) : createAnalytic(payload);
      const updatedAnalytic = await taskBag.task(action);

      if (!analytic.is_native) {
        await Promise.all(
          deployments.map(deployment => deploymentBag.removeDeployment(deployment.id, integrationGuid))
        );
      }

      await deploymentBag.deploy({
        overwrite: analytic.is_native,
        organization_id: defaultOrgId,
        integrations: [integrationGuid]
      });

      if (canEdit && analytic.is_native) {
        supplementalRefresh();
        updateAnalytic(updatedAnalytic);
        // setExclusions([]);
        // setExclusionsLoaded(false);
      }

      pushSnack('Saved', 'info', 'center', 'bottom', 5000);
      return updatedAnalytic.guid;
    } else {
      const payload: FPRCreate = isEmpty(fpr)
        ? {
            organization_id: defaultOrgId,
            integrations: [integrationGuid],
            analytics: [analytic.guid],
            raw: fprRaw
          }
        : {
            organization_id: fpr.organization_id,
            raw: fprRaw
          };
      return tuningBag.saveAndApply(fpr?.id, payload).then(() => {
        pushSnack('Saved', 'info', 'center', 'bottom', 5000);
        setIntegrationGuid(null);
        fprRefresh();
        return analytic.guid;
      });
    }
  }, [
    analytic.is_native,
    analytic.guid,
    state,
    user.name,
    canEdit,
    taskBag,
    deploymentBag,
    defaultOrgId,
    integrationGuid,
    pushSnack,
    deployments,
    supplementalRefresh,
    updateAnalytic,
    fpr,
    fprRaw,
    fprRefresh,
    tuningBag
  ]);

  const value: TuningInterface = React.useMemo(
    () => ({
      applyTuning,
      isSaving,
      canEdit,
      fieldType,
      fpr,
      dates,
      setDates,
      exclusionsLoaded,
      setExclusionsLoaded,
      selectedLanguage,
      integrationGuid,
      setIntegrationGuid,
      integrationType,
      isLogicChanged,
      deploymentBag,
      taskBag,
      translationBag,
      tuningBag,
      setShouldTranslate
    }),
    [
      applyTuning,
      canEdit,
      dates,
      deploymentBag,
      exclusionsLoaded,
      fieldType,
      fpr,
      integrationGuid,
      integrationType,
      isSaving,
      isLogicChanged,
      selectedLanguage,
      taskBag,
      translationBag,
      tuningBag
    ]
  );

  return <TuningContext.Provider value={value}>{children}</TuningContext.Provider>;
}
