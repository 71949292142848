import React from 'react';

import isEmpty from 'lodash/isEmpty';

import Paper from 'snap-ui/Paper';
import Stepper from 'snap-ui/Stepper';
import { styled } from 'snap-ui/util';

import useTitle from 'hooks/useTitle';

import { standardFormikBaseProps } from 'module/Form';
import Formik from 'module/Form/Formik';
import { NAVIGATION_HEADER_HEIGHT } from 'module/Scaffold/Scaffold.const';

import { Status } from 'storage';

import { NOOP } from 'utilities/FunctionUtils';
import { durationToMonths } from 'utilities/TimeUtils';

import {
  ExploitationStateOrderedList,
  SecurityProfile,
  SecurityProfileSchema,
  SecurityProfileStepDetail
} from './SecurityProfile.type';
import { requiresUpdate, transformValues } from './SecurityProfile.util';
import SecurityProfileStepApplications from './SecurityProfileStepApplications';
import SecurityProfileStepConfidenceThresholds from './SecurityProfileStepConfidenceThresholds';
import SecurityProfileStepExploitLikelihood from './SecurityProfileStepExploitLikelihood';
import SecurityProfileStepIndustries from './SecurityProfileStepIndustries';
import SecurityProfileStepMotivations from './SecurityProfileStepMotivations';
import SecurityProfileStepOfficeSoftware from './SecurityProfileStepOfficeSoftware';
import SecurityProfileStepOperatingSystems from './SecurityProfileStepOperatingSystems';
import SecurityProfileStepRegions from './SecurityProfileStepRegions';
import SecurityProfileStepScale from './SecurityProfileStepScale';
import SecurityProfileStepSeverityThresholds from './SecurityProfileStepSeverityThresholds';
import SecurityProfileStepThreatLastObserved from './SecurityProfileStepThreatLastObserved';
import SecurityProfileStepThreatPriorities from './SecurityProfileStepThreatPriorities';
import SecurityProfileStepThreatReview from './SecurityProfileStepThreatReview';
import SecurityProfileStepVulnerabilityCharacteristics from './SecurityProfileStepVulnerabilityCharacteristics';
import SecurityProfileStepVulnerabilityRisk from './SecurityProfileStepVulnerabilityRisk';
import useSecurityProfile, { useSecurityProfileWizard } from './useSecurityProfile';

const Container = styled(Paper, { label: 'SecurityProfileContainer' })`
  display: grid;
  grid-template-columns: 240px 1fr;

  .Stepper,
  .StepContent {
    padding: ${p => p.theme.spacing(3)};
    max-height: calc(100vh - ${NAVIGATION_HEADER_HEIGHT}px);
    overflow: auto;
  }

  & .MuiStepConnector-line {
    min-height: ${p => p.theme.spacing(3)}; // default is 24px
  }
`;

const steps: SecurityProfileStepDetail[] = [
  {
    label: 'Industries',
    content: <SecurityProfileStepIndustries />,
    keys: ['industries']
  },
  {
    label: 'Regions',
    content: <SecurityProfileStepRegions />,
    keys: ['regions']
  },
  {
    label: 'Scale',
    content: <SecurityProfileStepScale />,
    keys: ['scale']
  },
  {
    label: 'Adversary Motivations',
    content: <SecurityProfileStepMotivations />,
    keys: ['motivations']
  },
  {
    label: 'Operating Systems',
    content: <SecurityProfileStepOperatingSystems />,
    keys: ['platforms']
  },
  {
    label: 'Office Software',
    content: <SecurityProfileStepOfficeSoftware />,
    keys: ['platforms']
  },
  {
    label: 'Applications',
    content: <SecurityProfileStepApplications />,
    keys: ['applications']
  },
  {
    label: 'Severity Thresholds',
    content: <SecurityProfileStepSeverityThresholds />,
    keys: ['severity_thresholds']
  },
  {
    label: 'Confidence Thresholds',
    content: <SecurityProfileStepConfidenceThresholds />,
    keys: ['confidence_thresholds']
  },
  {
    label: 'Vulnerability Risk',
    content: <SecurityProfileStepVulnerabilityRisk />,
    keys: ['risk_tolerance']
  },
  {
    label: 'Vulnerability Characteristics',
    content: <SecurityProfileStepVulnerabilityCharacteristics />,
    keys: ['exploitation_state', 'exploited_zero_day', 'exploited_in_wild']
  },
  {
    label: 'Exploit Likelihood',
    content: <SecurityProfileStepExploitLikelihood />,
    keys: ['exploit_likelihood']
  },
  {
    label: 'Threat Priorities',
    content: <SecurityProfileStepThreatPriorities />,
    keys: ['threat_recency_threshold']
  },
  {
    label: 'Last Observed Threshold',
    content: <SecurityProfileStepThreatLastObserved />,
    keys: ['last_observed_threshold']
  },
  {
    label: 'Threat Review',
    content: <SecurityProfileStepThreatReview />
  }
];

function SecurityProfileWizard() {
  useTitle('Threat Profile | SnapAttack');
  const { organization } = useSecurityProfile();
  const { data: securityProfile, noProfileFound, status, incrementalUpdate } = useSecurityProfileWizard();
  const [clickableSteps, setClickableSteps] = React.useState<Record<string, boolean>>(() =>
    steps.reduce(
      (steps, step) => ({
        ...steps,
        [step.label]: true
      }),
      {}
    )
  );

  React.useEffect(() => {
    if (noProfileFound) {
      setClickableSteps({});
    }
  }, [noProfileFound]);

  const securityProfileFormInitial: SecurityProfile = {
    organization_id: securityProfile?.organization_id || organization?.id,
    industries: securityProfile?.industries || [],
    regions: securityProfile?.regions || [],
    scale: securityProfile?.scale ? securityProfile.scale + '' : '',
    motivations: securityProfile?.motivations || [],
    applications: securityProfile?.applications || [],
    platforms: securityProfile?.platforms || [],

    risk_tolerance: [securityProfile?.vulnerability_cvss_min || 0, 10],

    confidence_thresholds: [securityProfile?.confidence_min || 0, 1],
    severity_thresholds: [securityProfile?.severity_min || 0, 1],

    exploitation_state: [
      ExploitationStateOrderedList.findIndex(state => state === securityProfile?.vulnerability_exploit_min),
      4
    ],
    exploited_zero_day: securityProfile?.vulnerability_exploit_zero_day ? 'yes' : 'no',
    exploited_in_wild: securityProfile?.vulnerability_exploit_wild ? 'yes' : 'no',
    exploit_likelihood: [securityProfile?.vulnerability_epss_min * 100, 100],

    threat_recency_threshold: securityProfile?.threat_maturity * 100 || 50,
    last_observed_threshold: securityProfile?.observation_age_max
      ? durationToMonths(securityProfile.observation_age_max)
      : 7 * 12
  };

  return (
    <Formik<SecurityProfile>
      {...standardFormikBaseProps}
      initialValues={securityProfileFormInitial}
      onSubmit={NOOP}
      zodSchema={SecurityProfileSchema}
    >
      {({ values, touched }) => {
        function handleClick(step: number) {
          const { label, keys } = steps[step];
          setClickableSteps(clickableSteps => ({ ...clickableSteps, [label]: true }));

          if (!isEmpty(keys) && requiresUpdate(keys, values, touched, securityProfileFormInitial)) {
            incrementalUpdate(transformValues(keys, values));
          }
        }

        return (
          <Container>
            <Stepper
              steps={steps}
              savingStep={status === Status.pending}
              clickableSteps={clickableSteps}
              onClick={handleClick}
              buttons
              vertical
              nonLinear
            />
          </Container>
        );
      }}
    </Formik>
  );
}

export default SecurityProfileWizard;
