import React from 'react';

import { useField } from 'formik';
import isEmpty from 'lodash/isEmpty';

import { Autocomplete, Option } from 'snap-ui/Autocomplete';
import { CardProps } from 'snap-ui/Card/CheckableCards';
import Divider from 'snap-ui/Divider';
import { styled } from 'snap-ui/util';

import { countries, getCountryBy, FEATURED_COUNTRIES } from 'constants/countries';

import { CheckableCardCheckboxFormik } from 'module/Form/CheckableCardFormik';

import SecurityProfileStep from './SecurityProfileStep';

const FlagContainer = styled('div')`
  font-size: 20px;
  line-height: 16px;
`;

export default function SecurityProfileStepRegions(): JSX.Element {
  const [field, , helpers] = useField('regions');
  const [searchTerm, setSearchTerm] = React.useState<string>('');

  const [curatedLocations, setCuratedLocations] = React.useState<string[]>(FEATURED_COUNTRIES);

  const curatedLocationOptions: CardProps[] = curatedLocations.map(alpha3 => {
    const country = getCountryBy('alpha3', alpha3);
    return {
      value: country.alpha3,
      label: country.name,
      icon: <FlagContainer>{country.flag}</FlagContainer>
    };
  });

  const additionalLocationOptions: Option[] = countries
    .filter(country => !curatedLocations.includes(country.alpha3))
    .map(country => {
      return {
        value: country.alpha3,
        content: country.name
      };
    });

  React.useEffect(() => {
    setCuratedLocations(curatedLocations => {
      const newCuratedLocations = [...curatedLocations];
      if (!isEmpty(field.value))
        field.value.forEach(code => {
          if (!curatedLocations.includes(code)) newCuratedLocations.push(code);
        });
      return newCuratedLocations;
    });
  }, [field.value]);

  return (
    <SecurityProfileStep
      title='In which regions do you operate?'
      description='Threat actors adeptly tailor their strategies to target different regions, exploiting geopolitical tensions, cultural nuances, and regulatory variations to maximize the impact of their attacks. By customizing their tactics to exploit local vulnerabilities, threat actors can effectively bypass defenses and gain a foothold in specific regions, underscoring the importance of a globally-aware cybersecurity approach.'
      content={
        <>
          <CheckableCardCheckboxFormik name='regions' options={curatedLocationOptions} size='sm' />
          <Divider textAlign='left'>Specify another region</Divider>
          <Autocomplete
            name=''
            onInputChange={setSearchTerm}
            inputValue={searchTerm}
            disableUserAdditions
            onChange={(countryCode: string) => {
              if (countryCode) {
                helpers.setValue([...field.value, countryCode]);
                setSearchTerm('');
              }
            }}
            value=''
            options={additionalLocationOptions}
          />
        </>
      }
    />
  );
}
