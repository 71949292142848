import React from 'react';

import { faRight, faSearch } from '@fortawesome/pro-light-svg-icons';
import { faChevronRight, faTrash } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import get from 'lodash/get';
import { Link } from 'react-router-dom';

import { ButtonProps } from 'snap-ui/Button';
import {
  GridActionsCellItem,
  GridRenderCellParams,
  gridFilteredDescendantCountLookupSelector,
  useGridApiContext,
  useGridSelector
} from 'snap-ui/DataGrid';
import Icon from 'snap-ui/Icon';
import TextField from 'snap-ui/TextField';
import Tooltip from 'snap-ui/Tooltip';

import { ExportButton } from 'module/Hunt/Hunt.widget';

import { Ident } from 'types/common';

import { handleDebug } from 'utilities/FunctionUtils';

import SuperUserOrgSelect from '../Widgets/SuperUserOrgSelect';
import { JobOverview, JobType } from './Job.type';
import { JobTypeToggle } from './JobTypeToggle';
import { ArrowButton, JobGridToolbar } from './Task/Task.style';

export function TaskTableToolbar({
  jobTypeFilter,
  orgFilter,
  jobTypes,
  includeByOrg,
  includeExport,
  exportDataAsCsv,
  exportChildren,
  isExportPending,
  onSearchChange,
  onTypeChange,
  onOrgChange
}: {
  jobTypeFilter?: JobType | 'All';
  orgFilter: Ident;
  jobTypes: JobType[];
  includeByOrg: boolean;
  includeExport: boolean;
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onTypeChange?: (type: JobType | 'All') => void;
  onOrgChange: (org: number) => void;
  exportDataAsCsv?: () => Promise<void>;
  exportChildren: React.ReactNode;
  isExportPending: boolean;
}) {
  const isOrgEnabled = includeByOrg ?? true;
  const isExportEnabled = includeExport ?? true;

  return (
    <div>
      <JobGridToolbar>
        <div className='organization-search'>
          {isOrgEnabled && <SuperUserOrgSelect onChange={onOrgChange} value={orgFilter} />}
          <TextField
            className='search-field'
            onChange={onSearchChange}
            placeholder='Search name or integration'
            startAdornment={<Icon icon={faSearch} />}
          />
        </div>

        <JobTypeToggle jobTypeFilter={jobTypeFilter} jobTypes={jobTypes} onTypeChange={onTypeChange} />

        {isExportEnabled && (
          <ExportButton exportDataAsCsv={exportDataAsCsv} isExportPending={isExportPending}>
            {exportChildren}
          </ExportButton>
        )}
      </JobGridToolbar>
    </div>
  );
}

export function JobGridTreeDataGroupingCell(
  props: GridRenderCellParams & { idPath: string; linkPath: string; matchingGuid?: string }
) {
  const { id, field, rowNode, linkPath, idPath } = props;
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(apiRef, gridFilteredDescendantCountLookupSelector);
  const [expanded, setExpanded] = React.useState(false);
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

  const childRowId = apiRef.current.getRowGroupChildren({
    groupId: id
  });

  const childrenRowsOfGroup = childRowId.map(rowId => apiRef.current.getRow<JobOverview>(rowId));
  const row = apiRef.current.getRow(rowNode.id);
  const targetGuid = childrenRowsOfGroup?.find(childRow => childRow?.analytic_job_guid === props?.matchingGuid);

  const handleClick: ButtonProps['onClick'] = (event: React.MouseEvent<unknown>) => {
    if (rowNode.type !== 'group') {
      return;
    }

    setExpanded(!expanded);
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  const handleMouseEvent = (e: React.MouseEvent<HTMLDivElement>) =>
    handleDebug(e, () => {
      console.groupCollapsed(row?.analytic_job_guid);
      console.log('row: ', row);
      console.info('job job_guid', row?.analytic_job_guid);
      console.info('job group_guid', row?.analytic_job_group_guid);
      console.info('name', row?.name);
      console.groupEnd();
    });

  return (
    <div
      className={classNames(
        {
          'non-group-cell': rowNode.depth === 0,
          'child-group-cell': rowNode.depth > 0,
          'parent-group-cell': filteredDescendantCount > 0
        },
        'truncate-label'
      )}
      onMouseEnter={handleMouseEvent}
    >
      {filteredDescendantCount > 0 ? (
        <>
          <ArrowButton
            variant='text'
            type='button'
            className={classNames('arrow-button', { 'group-cell-expanded': expanded })}
            startIcon={<Icon icon={faChevronRight} />}
            onClick={handleClick}
            size='small'
          />
          <span>
            {targetGuid && <Icon icon={faRight} color='success' className='job-target' />}
            <Tooltip arrow className='field' placement='top' title={childrenRowsOfGroup[0]?.name} wrap>
              <Link to={`${linkPath}/${get(childrenRowsOfGroup, `0.${idPath}`)}`}>{childrenRowsOfGroup[0]?.name}</Link>
            </Tooltip>
          </span>
        </>
      ) : (
        <Tooltip arrow className='field' placement='top' title={row?.name} wrap>
          <Link to={`${linkPath}/${get(row, idPath)}`}>{row?.name}</Link>
        </Tooltip>
      )}
    </div>
  );
}

export function CellDeleteAction({ onDelete }: { onDelete(): void }) {
  const [active, setActive] = React.useState(false);

  const handleDelete = async () => {
    setActive(true);
    onDelete();
  };

  return (
    <GridActionsCellItem
      icon={active ? <Icon.SpinnerProgress /> : <Icon icon={faTrash} />}
      onClick={handleDelete}
      label='Delete'
    />
  );
}
