import isEqual from 'lodash/isEqual';

import {
  ControlElement,
  JsonFormsRendererRegistryEntry,
  JsonSchema,
  JsonSchema4,
  rankWith,
  scopeEndsWith,
  uiTypeIs,
  vanillaRenderers
} from 'module/JsonView';

import { Ops } from 'types/filter';

import { move } from 'utilities/ArrayUtils';

import { Control, MultiSelectControl, OpenSelectControl, SelectControl } from './Control';
import { AreaControl } from './Control/ControlArea';
import { FlagControl } from './Control/ControlFlag';
import { NumberControl } from './Control/ControlNumber';
import { TextControl } from './Control/ControlText';
import { FILTER_PREFIX } from './Metadata.const';
import { Metadata, MetadataFieldInput, MetadataSchema, Operation } from './Metadata.type';
import MetadataLayout from './MetadataLayout';

/**
 * https://jsonforms.io/examples/control
 * Options should be mapped to string[] prior to calling this function
 */
export function buildSchemasFromInput(values: MetadataFieldInput): MetadataSchema {
  if (!values.type) return null;
  const name = convertNameToIdentifier(values.label);

  const prop: JsonSchema4 = {
    title: values.label,
    description: values.description,
    properties: {
      appliesTo: {
        type: 'string',
        enum: values.applied
      },
      control: {
        type: 'string',
        title: values.type
      }
    }
  };

  // Even though we send empty string as default, default isn't returned from backend.
  switch (values.type) {
    case Control.Flag:
      prop.type = 'boolean';
      prop.default = 'any';
      break;
    case Control.Select:
      prop.type = 'string';
      prop.enum = values.options;
      prop.default = undefined;
      break;
    case Control.MultiSelect:
      prop.type = 'array';
      prop.items = {
        type: 'string',
        uniqueItems: true,
        enum: values.options
      };
      prop.default = [];
      break;
    case Control.Number:
      prop.type = 'number';
      prop.default = undefined;
      break;
    case Control.OpenSelect:
      prop.type = 'array';
      prop.uniqueItems = true;
      prop.default = [];
      break;
    default:
      prop.type = 'string';
      prop.default = '';
  }

  return {
    type: 'object',
    properties: {
      [name]: prop
    },
    uischema: {
      type: 'MetadataLayout',
      elements: [
        {
          type: 'Control',
          scope: `#/properties/${name}`
        } as ControlElement
      ]
    }
  };
}

function getControlFromType(type: Control) {
  switch (type) {
    case Control.Flag:
      return FlagControl;
    case Control.OpenSelect:
      return OpenSelectControl;
    case Control.MultiSelect:
      return MultiSelectControl;
    case Control.Number:
      return NumberControl;
    case Control.Select:
      return SelectControl;
    case Control.Text:
      return TextControl;
    case Control.TextArea:
      return AreaControl;
    default:
      return null;
  }
}

export function buildRenderersFromSchema(schema: JsonSchema): JsonFormsRendererRegistryEntry[] {
  return [
    { tester: rankWith(1000, uiTypeIs('MetadataLayout')), renderer: MetadataLayout },
    ...Object.entries(schema?.properties ?? ({} as MetadataSchema)).map(([key, value]) => ({
      tester: rankWith(3, scopeEndsWith(key)),
      renderer: getControlFromType(value.properties?.control?.title)
    })),
    /* Important to add our renderers first, otherwise vanillazilla will squash things */
    ...vanillaRenderers
  ];
}

/**
 * Converts a field control label into a schema property name
 */
export function convertNameToIdentifier(value: string) {
  return (value.replace(/\s/g, '') || '').toLowerCase();
}

export function splitControlKeyIntoWholeWords(value: string): string {
  return value.replace(/([a-z])([A-Z])/g, '$1 $2');
}

/** Account for boolean insanity with BE validation */
export function transform(value: unknown) {
  return value === 'true' ? true : value === 'false' ? false : value;
}

/** Account for boolean insanity with BE validation */
export function reform(value: unknown) {
  return value === true ? 'true' : value === false ? 'false' : value;
}

export function transformWithSchema(data: Metadata['value'], schema: MetadataSchema) {
  return Object.entries(data).reduce<Metadata['value']>(
    (prev, [key, value]) => ({
      ...prev,
      [key]: schema.properties[key]?.type === 'boolean' ? transform(value) : value
    }),
    {}
  );
}

export function getControlOrdering(schema: MetadataSchema): string[] {
  const elms = schema?.uischema?.elements;
  if (!elms) return [];
  return elms.map((o: ControlElement) => o?.scope?.split('/').pop());
}

export function sortSchemaControl(ordering: string[]) {
  return (a: { id: string }, b: { id: string }) => ordering.indexOf(a.id) - ordering.indexOf(b.id);
}

export function sortControlOptions(a: string, b: string) {
  return a?.toLowerCase().localeCompare(b?.toLowerCase());
}

export function getJsonViewChangedPayload(
  initial: Record<string, unknown>,
  data: Record<string, unknown>
): void | Record<string, unknown> {
  if (!data) return;
  if (isEqual(data, initial)) return;
  return Object.entries(data)
    .filter(([k]) => !k.endsWith('Op'))
    .reduce((prev, [key, value]) => {
      return {
        ...prev,
        [`${FILTER_PREFIX}${key}`]: value
      };
    }, {});
}

export function getJsonOperator(value: unknown): Ops | '' {
  return !value || value === 'any' || (value as string | string[])?.length === 0
    ? ''
    : Array.isArray(value)
    ? Ops.json_array_contains_any
    : value === 'true' || value === 'false'
    ? Ops.json_equals
    : Ops.json_contains;
}

export function isMetaEmpty(data: unknown) {
  return data === undefined || data === null || data === 'any' || data['length'] === 0;
}

export function replaceInSchema(current: MetadataSchema, next: MetadataSchema, property: string, insertIndex: number) {
  const result = addToSchema(removeFromSchema(current, property), next);
  return {
    ...result,
    uischema: {
      ...current.uischema,
      elements: move(current.uischema.elements, current.uischema.elements.length - 1, insertIndex)
    }
  };
}

export function addToSchema(current: MetadataSchema, addition: MetadataSchema) {
  return {
    ...current,
    properties: {
      ...current?.properties,
      ...addition?.properties
    },
    type: 'object',
    migrations: addition?.migrations,
    uischema: {
      ...current?.uischema,
      elements: [...(current?.uischema?.elements || []), ...addition.uischema.elements],
      type: 'MetadataLayout'
    }
  };
}

export function removeFromSchema(current: MetadataSchema, property: string) {
  const properties = {
    ...current.properties
  };
  delete properties[property];
  return {
    ...current,
    properties,
    type: 'object',
    uischema: {
      ...current.uischema,
      elements: (current.uischema?.elements as ControlElement[]).filter(v => !(v.scope?.split('/').pop() === property)),
      type: 'MetadataLayout'
    }
  };
}

export function reorderSchema(current: MetadataSchema, oldIndex: number, targetIndex: number) {
  return {
    ...current,
    type: 'object',
    migrations: [
      {
        operation: Operation.UIPropertyChange,
        field: 'uischema'
      }
    ],
    uischema: {
      ...current.uischema,
      elements: move(current.uischema.elements, oldIndex, targetIndex),
      type: 'MetadataLayout'
    }
  };
}
