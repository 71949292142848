import React from 'react';

import { faSignalBars } from '@fortawesome/pro-regular-svg-icons';
import { faBars, faDial, faEdit } from '@fortawesome/pro-solid-svg-icons';
import { useHistory } from 'react-router-dom';

import Menu from 'snap-ui/Menu';

import Path from 'constants/paths';

import { LauncherButton, LauncherDialog, LauncherInterface } from 'module/BAS/Launcher/useLauncherInterface';
import { useEntityScripts } from 'module/BAS/ScriptList';
import {
  AddArtifactToCollectionButton,
  AddArtifactToCollectionDialog,
  AddArtifactToCollectionInterface
} from 'module/Curation';
import { buildCuratedFilter } from 'module/Curation/Curation.service';
import {
  DetectionDeploymentDialog,
  DetectionDeploymentInterface,
  DetectionDeploymentMenuButton
} from 'module/DetectionDeployment';
import { JobType, huntInterfaceFactory } from 'module/Job';
import { BurgerLink } from 'module/Layout/Artifact.widgets';
import { HamburgerFab } from 'module/Layout/Styled';
import { useMayI } from 'module/May';
import { MoveOrgDialog, MoveOrgModalButton, MoveOrgModalInterface } from 'module/Organization';
import { SupplementalArtifact } from 'module/Search';

import { useAuth } from 'provider';

import { AnalyticReferences } from 'types/analytic';
import { ContentPermission, FunctionalPermission, Permitted } from 'types/auth';
import { Artifact, ArtifactType } from 'types/common';

import { getLocation } from 'utilities/RouteGenUtils';

import {
  AiDescriptionButton,
  AiDescriptionConfirmDialog,
  AiDescriptionInterface
} from './AiDescription/AiDescriptionProvider';
import useEditorStateContext, { getCloneState } from './EditorStateProvider';
import UndeployOnClone from './UndeployOnClone';
import useAnalyticPermission from './useAnalyticPermission';

const [HuntInterface, HuntButton, HuntDialog] = huntInterfaceFactory('HuntContext');
const [BulkConfidenceInterface, BulkConfidenceButton, BulkConfidenceDialog] =
  huntInterfaceFactory('BulkConfidenceContext');

type AnalyticMenuProps = {
  analytic: Artifact & { raw?: string };
  supplemental: SupplementalArtifact;
  permissions: Permitted;
  references: AnalyticReferences;
  disabled: boolean;
};

export default function AnalyticMenu(props: AnalyticMenuProps): JSX.Element {
  const { analytic, permissions, references, supplemental } = props;
  const guid = analytic?.guid;
  const { push } = useHistory();
  const { defaultOrgId } = useAuth();

  const buttonRef = React.useRef();
  const [open, setOpen] = React.useState(false);
  const [showUndeploy, setShowUndeploy] = React.useState(false);
  const scripts = useEntityScripts();
  const [canEdit] = useAnalyticPermission(ContentPermission.Edit);
  const canTune = useMayI(FunctionalPermission.Tuning);
  const [canClone, noCloneReason] = useAnalyticPermission(FunctionalPermission.CreateAnalytic);
  const [canDeploy, noDeployReason] = useAnalyticPermission(FunctionalPermission.DeployAnalytic);
  const { isLoaded: isEditorStateLoaded, state } = useEditorStateContext();

  const cloneLocation = {
    pathname: Path.IDE,
    state: {
      editor: getCloneState(state, defaultOrgId, guid)
    }
  };

  function handleCloneAndEdit() {
    push(cloneLocation);
  }

  return (
    <>
      <AddArtifactToCollectionInterface>
        <MoveOrgModalInterface type={ArtifactType.Analytic}>
          <HuntInterface jobType={JobType.Hunt}>
            <BulkConfidenceInterface jobType={JobType.Rank}>
              <LauncherInterface>
                <DetectionDeploymentInterface>
                  <AiDescriptionInterface>
                    <HamburgerFab
                      aria-label='Detection actions'
                      icon={faBars}
                      onClick={() => setOpen(!open)}
                      ref={buttonRef}
                      disabled={props.disabled}
                    />
                    <Menu
                      anchorEl={buttonRef.current}
                      onClick={() => setOpen(false)}
                      open={open}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                    >
                      {canEdit ? (
                        <BurgerLink
                          to={{
                            ...getLocation({
                              pathname: Path.IDE,
                              search: { detection: guid }
                            }),
                            state: {
                              editor: isEditorStateLoaded ? state : undefined,
                              item: analytic,
                              supplementalItem: supplemental,
                              referenceItem: references,
                              permissionItem: permissions
                            }
                          }}
                          title='Edit Detection'
                          icon={faEdit}
                        />
                      ) : isEditorStateLoaded ? (
                        <BurgerLink
                          disabled={!canClone}
                          TooltipProps={{ title: noCloneReason }}
                          to={cloneLocation}
                          title='Edit Detection'
                          icon={faEdit}
                        />
                      ) : null}

                      {canTune && (
                        <BurgerLink
                          to={{
                            pathname: `${Path.Detection}/${guid}/tuning`,
                            state: {
                              item: analytic
                            }
                          }}
                          title='Tune Detection'
                          icon={faDial}
                        />
                      )}

                      <AiDescriptionButton aiDescription={analytic?.ai_description} canEdit={canEdit} />
                      <MoveOrgModalButton />
                      <HuntButton title='Hunt Detection' />
                      <BulkConfidenceButton icon={faSignalBars} />

                      <DetectionDeploymentMenuButton
                        disabled={!canDeploy}
                        TooltipProps={{ title: noDeployReason }}
                        title='Deploy Detection'
                      />
                      <DetectionDeploymentMenuButton
                        disabled={!canDeploy}
                        TooltipProps={{ title: noDeployReason }}
                        title='Undeploy Detection'
                        undeploy
                      />

                      <LauncherButton scripts={scripts.data} />
                      <AddArtifactToCollectionButton />
                    </Menu>
                    <AiDescriptionConfirmDialog guid={guid} />
                    <AddArtifactToCollectionDialog type={ArtifactType.Analytic} guid={guid} />
                    <HuntDialog query={buildCuratedFilter([guid])} jobName={analytic.name} />
                    <BulkConfidenceDialog query={buildCuratedFilter([guid])} jobName={analytic.name} />
                    <MoveOrgDialog artifact={analytic} supplemental={supplemental} />
                    <DetectionDeploymentDialog criteria={buildCuratedFilter([guid])} />
                    <LauncherDialog scripts={scripts.data} />
                    <UndeployOnClone
                      isOpen={showUndeploy}
                      onClose={() => setShowUndeploy(false)}
                      onComplete={handleCloneAndEdit}
                    />
                  </AiDescriptionInterface>
                </DetectionDeploymentInterface>
              </LauncherInterface>
            </BulkConfidenceInterface>
          </HuntInterface>
        </MoveOrgModalInterface>
      </AddArtifactToCollectionInterface>
    </>
  );
}
