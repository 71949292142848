import React from 'react';

import { useHistory } from 'react-router-dom';

import { Alert } from 'snap-ui/Alert';
import Button, { ButtonProps } from 'snap-ui/Button';
import { ConfirmDialog } from 'snap-ui/Dialog';
import Typography from 'snap-ui/Typography';

import Path from 'constants/paths';

import { useAiFeatures } from 'hooks/useAiFeatures';

import { ApiError } from 'module/ApiError';
import { DiffViewer } from 'module/DiffEditor';
import { useIDEDetectionValue } from 'module/IDE';
import { SyntaxViewerPlaceholder } from 'module/Widgets/SyntaxEditor/SyntaxEditor';

import { useIntegrationCatalog } from 'provider';

import { isDefaultFPRDetection } from 'services/analyticService';

import { Status } from 'storage/Storage.type';

import { FunctionalPermission } from 'types/auth';

import { useAnalyticCatalog } from '../core/AnalyticProvider';
import useAnalyticPermission from '../core/useAnalyticPermission';
import { TuningPreviewContainer } from './AnalyticTuning.style';
import useTuningContext from './AnalyticTuningProvider';

type Button = Partial<ButtonProps>;

interface TuningPreviewInterface {
  isOpen: boolean;
  setIsOpen(open: boolean): void;
}

const TuningPreviewContext = React.createContext<TuningPreviewInterface>(null);
TuningPreviewContext.displayName = 'TuningPreviewContext';

export function TuningPreviewInterface({ children }: { children: React.ReactNode }): JSX.Element {
  const [isOpen, setIsOpen] = React.useState(false);

  return <TuningPreviewContext.Provider value={{ isOpen, setIsOpen }}>{children}</TuningPreviewContext.Provider>;
}

export function useTuningPreview(): TuningPreviewInterface {
  const context = React.useContext(TuningPreviewContext);
  if (!context) {
    throw new Error('useTuningPreview must be used within an TuningPreviewInterface');
  }
  return context;
}

export function TuningPreviewButton({ onClick, ...others }: Button): JSX.Element {
  const { setIsOpen } = useTuningPreview();
  return (
    <Button
      onClick={() => {
        setIsOpen(true);
        if (onClick) onClick();
      }}
      {...others}
    >
      Review Logic
    </Button>
  );
}

export function TuningPreviewDialog() {
  const { canUseAiFeatures } = useAiFeatures();
  const { push } = useHistory();
  const { isOpen, setIsOpen } = useTuningPreview();
  const [canSave, noSaveReason] = useAnalyticPermission(FunctionalPermission.Tuning);
  const {
    applyTuning,
    isSaving,
    canEdit,
    deploymentBag,
    taskBag,
    translationBag,
    tuningBag,
    setShouldTranslate,
    selectedLanguage,
    integrationGuid,
    isLogicChanged
  } = useTuningContext();

  const { status: translationStatus, translation, fprTranslation, error: translationError } = translationBag;
  const [{ analytic }] = useAnalyticCatalog();
  const { integrations } = useIntegrationCatalog();
  const integration = integrations.all.find(i => i.guid === integrationGuid);
  const translationLoading = translationStatus === Status.pending;

  const { value: customizationValue } = useIDEDetectionValue('customization');

  React.useEffect(() => {
    setShouldTranslate(isOpen);
    return () => setShouldTranslate(false);
  }, [isOpen, setShouldTranslate]);

  function handleApplyTuning() {
    applyTuning().then(guid => {
      setIsOpen(false);
      push(`${Path.Detection}/${guid}`);
    });
  }

  function handleEdit() {
    setIsOpen(false);
    push(`${Path.Detection}/${analytic.guid}/fine_tuning`);
  }

  return (
    <ConfirmDialog
      key='preview'
      title='Tuned Detection'
      isPending={isSaving}
      DialogProps={{
        open: isOpen,
        onClose: () => setIsOpen(false),
        maxWidth: 'md'
      }}
      ConfirmProps={{
        children: 'Apply Tuning',
        disabled: translationLoading || !canSave || isDefaultFPRDetection(customizationValue) || !isLogicChanged,
        onClick: handleApplyTuning
      }}
      SecondaryProps={{
        children: 'Edit',
        disabled: isSaving || translationLoading,
        onClick: handleEdit
      }}
    >
      <TuningPreviewContainer>
        <ApiError {...tuningBag.errorProps} />
        <ApiError {...taskBag.errorProps} />
        <ApiError {...deploymentBag.errorProps} />
        <ApiError messages={translationError ? [translationError] : []} />
        {!canUseAiFeatures && (
          <Alert severity='info'>
            Tuning logic may be added to a detection with generative AI, which has been disabled by your organization
            administrator. Please review the output. You may have to manually insert the tuning logic.
          </Alert>
        )}
        {!canSave && <Alert severity='warning'>{noSaveReason}</Alert>}
        <Typography variant='body1'>
          Review the final detection with tuning logic highlighted below.{' '}
          {!analytic.is_native // Sigma Detection, apply FPR
            ? 'If this is correct, the tuning logic will be applied to the detection and automatically redeployed.'
            : canEdit // Native Detections, application depends on permissions
            ? `If you apply this tuning, the detection will be updated as a native ${integration?.name} detection. The true positive rule and tuning logic will be merged into a single detection.`
            : `If you apply this tuning, the detection will be cloned to a new native ${integration?.name} detection. The true positive rule and tuning logic will be merged into a single detection. Existing versions of the parent detection will be undeployed once tuning is complete.`}{' '}
          If the logic is not correct, click <code>Edit</code> to make additional adjustments.
        </Typography>
        {translationLoading ? (
          <TuningPreviewPlaceholder />
        ) : (
          <DiffViewer
            original={translation}
            value={fprTranslation || translation}
            grammar={selectedLanguage?.native_key}
          />
        )}
      </TuningPreviewContainer>
    </ConfirmDialog>
  );
}

export function TuningPreviewPlaceholder() {
  return <SyntaxViewerPlaceholder rows={12} />;
}
