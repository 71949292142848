import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { styled } from 'snap-ui/util';

import { JsonView } from 'module/JsonView';

import { ArtifactType } from 'types/common';

import { Metadata } from './Metadata.type';

export type MetadataBulk = {
  data: Metadata;
  onChange(data: Metadata['value']): void;
  type: ArtifactType;
};

const Container = styled('div')`
  .group-layout {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: ${p => p.theme.spacing(4)};
  }

  .group-layout-item > .Control {
    width: 100%;
  }
`;

export function MetadataBulk(props: MetadataBulk) {
  const { data, onChange, type } = props;
  const handleChange: JsonView['onChange'] = payload => !isEmpty(payload.data) && onChange(payload.data);
  if (!data) return null;
  return (
    <Container>
      <JsonView
        schema={data.meta}
        uischema={data.meta.uischema}
        data={data.value}
        onChange={handleChange}
        type={type}
      />
    </Container>
  );
}
