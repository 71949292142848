import React from 'react';

import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { faBan, faCopy, faPlay, faTrash } from '@fortawesome/pro-solid-svg-icons';
import mean from 'lodash/mean';
import sum from 'lodash/sum';
import { Link } from 'react-router-dom';

import {
  GridAggregationFunction,
  GridApi,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridValueFormatterParams,
  GridValueGetterParams,
  TruncateCell,
  gridStringOrNumberComparator
} from 'snap-ui/DataGrid';
import Icon from 'snap-ui/Icon';
import { MenuButton, MenuTrigger } from 'snap-ui/Menu';
import Placeholder from 'snap-ui/Placeholder';

import Path from 'constants/paths';

import { BurgerClicker } from 'module/Layout/Artifact.widgets';
import CopyButton from 'module/Widgets/CopyButton';
import { VALUE_MAP } from 'module/Widgets/StateLabel/DetectionJob';

import { Organization } from 'types/auth';

import { formatDayTimestamp, transformSecondsToHumanFormat } from 'utilities/TimeUtils';

import { DISPLAY_JOB_STATUS_DETAIL } from '../Job.const';
import { Job, JobOverview, JobOverviewDetail, JobStatus, JobTypeTableMap } from '../Job.type';
import { convertJobStatus, getGroupJobStatus, getJobStatusMessage } from '../Job.util';
import { GuidContainer, JobStateLabel, JobStatusTooltip } from './Task.style';

type Col<T> = GridColDef<T>;

export const detectionColumns = [
  {
    field: 'id',
    headerName: 'ID'
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    renderCell: p => (
      <Link to={`${Path.Detection}/${p.row.guid}`} target='blank'>
        {p.value}
      </Link>
    )
  },
  {
    field: 'total_hits',
    headerName: 'Hits',
    type: 'number',
    flex: 0.4,
    valueFormatter: (p: GridValueFormatterParams) => p.value
  }
];

export const aggregateIntegration: GridAggregationFunction<string> = {
  apply: ({ values }) => values.join(', '),
  label: '',

  columnTypes: ['string']
};

export const aggregateType: GridAggregationFunction<string> = {
  apply: ({ values }) => values[0],
  label: '',

  columnTypes: ['string']
};

export const aggregateCreatedBy: GridAggregationFunction<string> = {
  apply: ({ values }) => values[0],
  label: '',

  columnTypes: ['string']
};

export const aggregateOrg: GridAggregationFunction<string> = {
  apply: ({ values }) => values[0],
  label: '',

  columnTypes: ['string']
};

export const aggregateStatus: GridAggregationFunction<string> = {
  apply: ({ values }) => getGroupJobStatus(values as JobStatus[]),
  label: '',

  columnTypes: ['string']
};

export const aggregateProgress: GridAggregationFunction<number> = {
  apply: ({ values }) => {
    if (!values) return 0;
    return mean(values);
  },
  label: '',

  columnTypes: ['number']
};

export const sumUp: GridAggregationFunction<number> = {
  apply: ({ values }) => sum(values),
  label: '',

  columnTypes: ['number']
};

export const aggregateTimeRemaining: GridAggregationFunction<number> = {
  apply: ({ values }) => {
    return Math.max(...values);
  },
  label: '',

  columnTypes: ['number']
};

export const aggregateCreated: GridAggregationFunction<string> = {
  apply: ({ values }) => {
    if (!values) return '';
    return values.sort().reverse()[0];
  },
  label: '',

  columnTypes: ['string']
};

export const jobsByGroup: Col<Job> = {
  field: 'jobsByGroup',
  headerName: 'JobsByGroup',
  disableColumnMenu: true
};

export const jobName: Col<Job> = {
  field: 'name',
  headerName: 'N/A',
  disableColumnMenu: true
};

export const jobGuid = (superuser: boolean): Col<JobOverview> => {
  return {
    field: 'analytic_job_guid',
    headerName: 'Job ID',
    disableColumnMenu: true,
    minWidth: 125,
    renderCell: p => {
      if (!p.value || !superuser) return null;
      return (
        <CopyButton
          value={p.row.analytic_job_guid}
          placement='bottom'
          copyTooltip='Copied to clipboard!'
          messageTime={2000}
          arrow
        >
          <GuidContainer>
            <Icon className='copy-icon ' icon={faCopy} />
            <TruncateCell>{p.value}</TruncateCell>
          </GuidContainer>
        </CopyButton>
      );
    }
  };
};

export const type: Col<Job> = {
  field: 'type',
  headerName: 'Type',
  flex: 0.5,
  minWidth: 100,
  valueGetter: (p: GridValueGetterParams) => JobTypeTableMap[p.value],
  renderCell: (p: GridRenderCellParams) => <TruncateCell>{p.value}</TruncateCell>
};

export const integrationNames: Col<Job> = {
  field: 'integration_name',
  headerName: 'Integrations',
  flex: 0.6,
  minWidth: 125,
  valueGetter: (p: GridValueGetterParams<Job>) => p.value,
  renderCell: (p: GridRenderCellParams<Job>) => <TruncateCell>{p.value}</TruncateCell>
};

export const created_by: Col<Job> = {
  field: 'created_by',
  headerName: 'Created By',
  flex: 0.5,
  minWidth: 125,
  valueGetter: (p: GridValueGetterParams<Job>) => p.value,
  renderCell: (p: GridRenderCellParams<Job>) => <TruncateCell>{p.value}</TruncateCell>
};

export const taskStatus = (apiRef: React.MutableRefObject<GridApi>): Col<JobOverview> => {
  return {
    field: 'job_status',
    headerName: 'Status',
    width: 120,
    sortingOrder: ['asc', 'desc'],
    sortComparator: (v1, v2, param1, param2) =>
      gridStringOrNumberComparator(VALUE_MAP[v1].label, VALUE_MAP[v2].label, param1, param2),
    renderCell: p => {
      const status = convertJobStatus(p.value);
      const presentDetail = DISPLAY_JOB_STATUS_DETAIL.includes(status);

      const isGroupRow = p.rowNode.type === 'group';

      const childRowId = apiRef?.current.getRowGroupChildren({
        groupId: p.id
      });
      const childrenRowsOfGroup = childRowId?.map(rowId => apiRef.current.getRow<JobOverviewDetail>(rowId));
      const groupRowProgress = mean(childrenRowsOfGroup?.map(row => row.progress_percent));
      const message = getJobStatusMessage(status, isGroupRow ? groupRowProgress : p.row.progress_percent);

      const statusDetail = () => {
        if (!presentDetail) return '';
        else if (isGroupRow) {
          return (
            <div>
              <dt>{message}</dt>
              <div>
                {childrenRowsOfGroup.map(job => {
                  if (!job?.detections_failed) return '';
                  return (
                    <div key={job.integration_guid}>
                      <dt>{job.integration_name || 'Unknown'}</dt>
                      <dd>
                        {job?.detections_failed} error{job?.detections_failed > 1 ? 's' : ''}
                      </dd>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        } else if (message)
          return (
            <div>
              <dt>{message}</dt>
              {p.row?.detections_failed ? (
                <div>
                  <dt>{p.row.integration_name || 'Unknown'}</dt>
                  <dd>
                    {p.row?.detections_failed} error{p.row?.detections_failed > 1 ? 's' : ''}
                  </dd>
                </div>
              ) : (
                ''
              )}
            </div>
          );
      };

      return (
        <JobStatusTooltip arrow placement='top' title={statusDetail()} wrap>
          <JobStateLabel value={status} />
        </JobStatusTooltip>
      );
    }
  };
};

export const progress: Col<JobOverview> = {
  field: 'progress_percent',
  headerName: 'Progress',
  type: 'number',
  maxWidth: 70,
  valueFormatter: p => {
    if (!p.value) return '';
    return `${Math.floor(p.value * 100)}%`;
  }
};

export const detections: Col<Job> = {
  field: 'detections_completed',
  headerName: 'Detections',
  type: 'number',
  minWidth: 100,
  valueGetter: p => parseFloat(p.value)
};

export const hits: Col<Job> = {
  field: 'total_hits',
  headerName: 'Hits',
  type: 'number',
  minWidth: 75
};

export const timeRemaining: Col<Job> = {
  field: 'estimated_remaining_sec',
  headerName: 'Time Remaining',
  type: 'string',
  minWidth: 125,
  valueGetter: p => transformSecondsToHumanFormat(parseFloat(p.value), 'minutes')
};

export const creation: Col<Job> = {
  field: 'creation',
  headerName: 'Created',
  valueFormatter: p => formatDayTimestamp(p.value),
  type: 'string',
  flex: 0.3,
  minWidth: 125
};

export const modified: Col<Job> = {
  field: 'modified',
  headerName: 'Modified',
  valueFormatter: p => formatDayTimestamp(p.value),
  type: 'string',
  flex: 0.3,
  minWidth: 125
};

export const organization = (organizations: Organization[]): Col<Job> => ({
  field: 'organization_id',
  headerName: 'Organization',
  renderCell: p => {
    const name = organizations?.find(org => org.id === p.value)?.name;
    if (!name) return <Placeholder variant='text' width={100} />;
    return <TruncateCell>{name}</TruncateCell>;
  },
  type: 'string',
  minWidth: 100
});

export const taskActions = ({
  isDetectionFeaturesUser,
  deleteJobs,
  pause,
  resume,
  cancel,
  apiRef
}: {
  isDetectionFeaturesUser: boolean;
  deleteJobs: (guid: string[]) => void;
  pause: (guid: string[]) => void;
  resume: (guid: string[]) => void;
  cancel: (guid: string[]) => void;
  apiRef: React.MutableRefObject<GridApi>;
}): Col<JobOverview> => ({
  field: 'actions',
  headerName: 'Actions',
  type: 'actions',
  minWidth: 40,
  getActions: (p: GridRowParams<JobOverview>) => {
    const canPaused = p.row.can_pause;
    const canResume = p.row.can_resume;
    const canCancel = p.row.can_cancel;

    const isGroupRow = !p.row?.analytic_job_id;
    const childRowId = apiRef?.current.getRowGroupChildren({
      groupId: p.id
    });
    const childrenRowsOfGroup = childRowId?.map(rowId => apiRef.current.getRow<JobOverviewDetail>(rowId));

    const rowJobGuids = isGroupRow ? childrenRowsOfGroup.map(job => job.analytic_job_guid) : [p.row.analytic_job_guid];

    return [
      <MenuTrigger
        key='options'
        className='options'
        aria-label='options-trigger'
        trigger={<MenuButton aria-label='options-menu' icon={faEllipsisVertical} />}
      >
        {canPaused && isDetectionFeaturesUser && (
          <BurgerClicker title='Pause' label='Pause' icon={faPlay} onClick={() => pause(rowJobGuids)} />
        )}
        {canResume && isDetectionFeaturesUser && (
          <BurgerClicker title='Resume' label='Resume' icon={faPlay} onClick={() => resume(rowJobGuids)} />
        )}
        {canCancel && isDetectionFeaturesUser && (
          <BurgerClicker title='Cancel' label='Cancel' icon={faBan} onClick={() => cancel(rowJobGuids)} />
        )}

        <BurgerClicker title='Delete' label='Delete' icon={faTrash} onClick={() => deleteJobs(rowJobGuids)} />
      </MenuTrigger>
    ];
  }
});
